import api from '@/api'
import { Approval, ApprovalInput, ApprovalValue } from '@/models/approval'
import { AxiosError } from 'axios'

export const fetchApprovals = async () => {
  return await api
    .get('v1/approvals')
    .then((resp: any) => (resp.data ?? []).map((it: any) => Approval.createFromJson(it)))
    .catch(() => [])
}

export const fetchRequiredApprovals = async (): Promise<ApprovalValue[]> => {
  return await api
    .get('v1/requiredApprovals')
    .then((resp: any) => (resp.data.approvals ?? []).map((it: any) => Approval.createApprovalValue(it)))
    .catch(() => [])
}

export const approve = async (input: ApprovalInput): Promise<Approval> => {
  return await api
    .post('v1/approvals', input)
    .then(async (resp: any) => Approval.createFromJson(resp.data))
    .catch((e: AxiosError) => {
      throw e
    })
}

export const deleteRecords = async (input: string[]): Promise<boolean> => {
  return await api
    .post('v1/records/delete', input)
    .then((resp: any) => resp.data)
    .catch((e: AxiosError) => {
      throw e
    })
}
