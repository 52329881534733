import { DataType } from './dataType'

export class Approval {
  public static createFromJson(json: any): Approval {
    return new Approval(
      json.id ?? null,
      json.tenantId,
      json.requestFrom,
      (json.approvals ?? []).map((it: any) => Approval.createApprovalValue(it)),
      json.createdAt ? new Date(json.createdAt) : null,
      json.updatedAt ? new Date(json.updatedAt) : null,
    )
  }

  public static createApprovalValue(json: any): ApprovalValue {
    return {
      typeIdentifier: json.typeIdentifier,
      customId: json.customId || null,
      create: json.create || false,
      read: json.read || false,
      delete: json.delete || false,
      entrustApproval: json.entrustApproval || null,
    }
  }

  public setLabels(dataTypes: DataType[]) {
    this.approvals.forEach((approval) => {
      approval.label = dataTypes.find((it) => {
        return it.typeIdentifier === approval.typeIdentifier && it.customId === approval.customId
      })?.label
    })
    this.approvals = this.approvals.filter((it) => it.label)
  }

  constructor(
    public id: string | null,
    public tenantId: string,
    public requestFrom: string,
    public approvals: ApprovalValue[],
    public createdAt: Date | null,
    public updatedAt: Date | null,
  ) {}
}

export type ApprovalValue = {
  typeIdentifier: string
  customId: string | null
  create: boolean
  read: boolean
  delete: boolean
  entrustApproval: {
    create: boolean
    read: boolean
    delete: boolean
  } | null
  label?: string
}

export type ApprovalInput = {
  tenantId: string | null
  approvals: ApprovalValue[]
}
